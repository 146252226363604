// whiteBoard:
export const DESELECT_NODE = 'DESELECT_NODE';
export const DESELECT_NODES = 'DESELECT_NODES';
export const SELECT_NODE = 'SELECT_NODE';
export const ADD_NODE_REQUEST = 'ADD_NODE_REQUEST';
export const ADD_NODE_SUCCESS = 'ADD_NODE_SUCCESS';
export const ADD_NODE_FAILURE = 'ADD_NODE_FAILURE';
export const UPDATE_SHAPE_PROPS_REQUEST = 'UPDATE_SHAPE_PROPS_REQUEST';
export const UPDATE_SHAPE_PROPS_SUCCESS = 'UPDATE_SHAPE_PROPS_SUCCESS';
export const UPDATE_SHAPE_PROPS_FAILURE = 'UPDATE_SHAPE_PROPS_FAILURE';
export const ADD_POINTS_TO_LINE = 'ADD_POINTS_TO_LINE';
export const COMPLETE_LINE_REQUEST = 'COMPLETE_LINE_REQUEST';
export const COMPLETE_LINE_SUCCESS = 'COMPLETE_LINE_SUCCESS';
export const COMPLETE_LINE_FAILURE = 'COMPLETE_LINE_FAILURE';
export const CHANGE_MODE = 'CHANGE_MODE';
export const REMOVE_SELECTED_NODES = 'REMOVE_SELECTED_NODES';
export const INIT_WHITEBOARD = 'INIT_WHITEBOARD';

// account
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const DISABLE_SIGNUPING = 'DISABLE_SIGNUPING';

export const GROUP_SIGNUP_REQUEST = 'GROUP_SIGNUP_REQUEST';
export const GROUP_SIGNUP_SUCCESS = 'GROUP_SIGNUP_SUCCESS';
export const GROUP_SIGNUP_FAILURE = 'GROUP_SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CHECK_PAYMENT_REQUEST = 'CHECK_PAYMENT_REQUEST';
export const CHECK_PAYMENT_SUCCESS = 'CHECK_PAYMENT_SUCCESS';
export const CHECK_PAYMENT_FAILURE = 'CHECK_PAYMENT_FAILURE';
export const REMOVE_PAYMENT_DATA = 'REMOVE_PAYMENT_DATA';

export const SEND_SOLUTION_REQUEST = 'SEND_SOLUTION_REQUEST';
export const SEND_SOLUTION_SUCCESS = 'SEND_SOLUTION_SUCCESS';
export const SEND_SOLUTION_FAILURE = 'SEND_SOLUTION_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';

export const REQUEST_MENTOR_REQUEST = 'REQUEST_MENTOR_REQUEST';
export const REQUEST_MENTOR_SUCCESS = 'REQUEST_MENTOR_SUCCESS';
export const REQUEST_MENTOR_FAILURE = 'REQUEST_MENTOR_FAILURE';

export const GO_TO_TEAM_REQUEST = 'GO_TO_TEAM_REQUEST';
export const GO_TO_TEAM_SUCCESS = 'GO_TO_TEAM_SUCCESS';
export const GO_TO_TEAM_FAILURE = 'GO_TO_TEAM_FAILURE';

// fsm
export const CREATE_FSM_REQUEST = 'CREATE_FSM_REQUEST';
export const CREATE_FSM_SUCCESS = 'CREATE_FSM_SUCCESS';
export const CREATE_FSM_FAILURE = 'CREATE_FSM_FAILURE';

export const CREATE_STATE_REQUEST = 'CREATE_STATE_REQUEST';
export const CREATE_STATE_SUCCESS = 'CREATE_STATE_SUCCESS';
export const CREATE_STATE_FAILURE = 'CREATE_STATE_FAILURE';

export const GET_FSM_REQUEST = 'GET_FSM_REQUEST';
export const GET_FSM_SUCCESS = 'GET_FSM_SUCCESS';
export const GET_FSM_FAILURE = 'GET_FSM_FAILURE';

export const GET_WORKSHOP_REQUEST = 'GET_WORKSHOP_REQUEST';
export const GET_WORKSHOP_SUCCESS = 'GET_WORKSHOP_SUCCESS';
export const GET_WORKSHOP_FAILURE = 'GET_WORKSHOP_FAILURE';

export const EDIT_EDGES_REQUEST = 'EDIT_EDGES_REQUEST';
export const EDIT_EDGES_SUCCESS = 'EDIT_EDGES_SUCCESS';
export const EDIT_EDGES_FAILURE = 'EDIT_EDGES_FAILURE';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE';

export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_FAILURE = 'DELETE_WIDGET_FAILURE';

export const CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_FAILURE = 'CREATE_WIDGET_FAILURE';

export const GET_WIDGET_REQUEST = 'GET_WIDGET_REQUEST';
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS';
export const GET_WIDGET_FAILURE = 'GET_WIDGET_FAILURE';

export const GET_PAGES_REQUEST = 'GET_PAGES_REQUEST';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAILURE = 'GET_PAGES_FAILURE';

export const GET_CURRENT_PAGE_REQUEST = 'GET_CURRENT_PAGE_REQUEST';
export const GET_CURRENT_PAGE_SUCCESS = 'GET_CURRENT_PAGE_SUCCESS';
export const GET_CURRENT_PAGE_FAILURE = 'GET_CURRENT_PAGE_FAILURE';

export const SUBMIT_TEAM_REQUEST = 'SUBMIT_TEAM_REQUEST';
export const SUBMIT_TEAM_SUCCESS = 'SUBMIT_TEAM_SUCCESS';
export const SUBMIT_TEAM_FAILURE = 'SUBMIT_TEAM_FAILURE';

export const MOVE_TO_NEXT_STATE_REQUEST = 'MOVE_TO_NEXT_STATE_REQUEST';
export const MOVE_TO_NEXT_STATE_SUCCESS = 'MOVE_TO_NEXT_STATE_SUCCESS';
export const MOVE_TO_NEXT_STATE_FAILURE = 'MOVE_TO_NEXT_STATE_FAILURE';

export const SET_FIRST_CURRENT_PAGE_REQUEST = 'SET_FIRST_CURRENT_PAGE_REQUEST';
export const SET_FIRST_CURRENT_PAGE_SUCCESS = 'SET_FIRST_CURRENT_PAGE_SUCCESS';
export const SET_FIRST_CURRENT_PAGE_FAILURE = 'SET_FIRST_CURRENT_PAGE_FAILURE';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const GET_TEAM_OUTWARD_EDGES_REQUEST = 'GET_TEAM_OUTWARD_EDGES_REQUEST';
export const GET_TEAM_OUTWARD_EDGES_SUCCESS = 'GET_TEAM_OUTWARD_EDGES_SUCCESS';
export const GET_TEAM_OUTWARD_EDGES_FAILURE = 'GET_TEAM_OUTWARD_EDGES_FAILURE';

export const SEND_ANSWER_REQUEST = 'SEND_ANSWER_REQUEST';
export const SEND_ANSWER_SUCCESS = 'SEND_ANSWER_SUCCESS';
export const SEND_ANSWER_FAILURE = 'SEND_ANSWER_FAILURE';

// message
export const SHIFT_MESSAGE = 'SHIFT_MESSAGE';
export const SHIFT_REDIRECT = 'SHIFT_REDIRECT';

//graph:
export const CREATE_VERTICE = 'CREATE_VERTICE';
export const CREATE_EDGE = 'CREATE_EDGE';
export const UPDATE_VERTICE = 'UPDATE_VERTICE';
export const UPDATE_EDGE = 'UPDATE_EDGE';
export const SELECT_VERTICE = 'SELECT_VERTICE';
export const SELECT_EDGE = 'SELECT_EDGE';
export const DESELECT_VERTICE = 'DESELECT_VERTICE';
export const DESELECT_EDGE = 'DESELECT_EDGE';
export const REMOVE_SELECTED_VERTICES = 'REMOVE_SELECTED_VERTICES';
export const REMOVE_SELECTED_EDGES = 'REMOVE_SELECTED_EDGES';
export const DELETE_VERTEX = 'DELETE_VERTEX';
export const DELETE_EDGE = 'DELETE_EDGE';
export const RESET_GRAPH = 'RESET_GRAPH';
export const UPDATE_COST = 'UPDATE_COST';
export const UPDATE_ADDED_FLUX = 'UPDATE_ADDED_FLUX';
export const INITIAL_VERTICES = 'INITIAL_VERTICES';
export const INITIAL_EDGES = 'INITIAL_EDGES';

//coding graph
export const CREATE_DOT = 'CREATE_DOT';
export const SELECT_DOT = 'SELECT_DOT';
export const DESELECT_DOT = 'DESELECT_DOT';
export const CREATE_CIRCLE = 'CREATE_CIRCLE';
export const UPDATE_CIRCLE = 'UPDATE_CIRCLE';
export const CREATE_SQUARE = 'CREATE_SQUARE';
export const UPDATE_SQUARE = 'UPDATE_SQUARE';
export const CIRCLE_MODE = 'CIRCLE_MODE';
export const SQUARE_MODE = 'SQUARE_MODE';
export const TRIANGLE_MODE = 'TRIANGLE_MODE';
export const CLEAR_SHAPE = 'CLEAR_SHAPE';
export const ADD_EDGE = 'ADD_EDGE';
export const UPDATE_EDGEE = 'UPDATE_EDGEE';
export const UPDATE_DISTANCE = 'UPDATE_DISTANCE';

//coding grid
export const UPDATE_DOT = 'UPDATE_DOT';
export const UPDATE_LINE = 'UPDATE_LINE';
export const INSERT_DOTS = 'INSERT_DOTS';
export const INSERT_LINES = 'INSERT_LINES';
export const RESET_GRID = 'RESET_GRID';

//algorithm: first simulation
export const CREATE_BOX = 'CREATE_BOX';
export const UPDATE_BOX = 'UPDATE_BOX';
export const CREATE_MACHINE = 'CREATE_MACHINE';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const DELETE_BOX = 'DELETE_BOX';
export const RESET_BOXES = 'RESET_BOXES';
export const NEXT_ROUND = 'NEXT_ROUND';
export const CHANGE_GAME_MODE = 'CHANGE_GAME_MODE';
export const CHANGE_TOTAL_TIME = 'CHANGE_TOTAL_TIME';
export const NEXT_LEVEL = 'NEXT_LEVEL';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const CHANGE_BOXES = 'CHANGE_BOXES';

//algorithm: dummies game
export const CREATE_DUMMY = 'CREATE_DUMMY';
export const UPDATE_DUMMY = 'UPDATE_DUMMY';
export const NEXT_DUMMY_ROUND = 'NEXT_DUMMY_ROUND';
export const WRONG_ANSWERED = 'WRONG_ANSWERED';
export const RESET_DUMMY_GAME = 'RESET_DUMMY_GAME';
export const DUMMY_NEXT_LEVEl = 'DUMMY_NEXT_LEVEl';
export const DUMMIES_CHANGE_MODE = 'DUMMIES_CHANGE_MODE';

//coding: vertical lines
export const CREATE_POINTS = 'CREATE_POINTS';
export const CREATE_POINT = 'CREATE_POINT';
export const UPDATE_POINT = 'UPDATE_POINT';
export const CREATE_LINE = 'CREATE_LINE';
export const RESET_POINTS = 'RESET_POINTS';

//coding: x-question
export const UPDATE_SETS = 'UPDATE_SETS';
export const UPDATE_STATE_OF_SETS = 'UPDATE_STATE_OF_SETS';
export const UPDATE_GUESSED_NUMBER = 'UPDATE_GUESSED_NUMBER';
export const UPDATE_INTERVIEWER_ANSWER = 'UPDATE_INTERVIEWER_ANSWER';
export const UPDATE_QUESTION_STATUS = 'UPDATE_QUESTION_STATUS';

//physics: freeFall
export const UPDATE_FREEFALL_STATE = 'UPDATE_FREEFALL_STATE';
export const UPDATE_FREEFALL_PAGENUMBER = 'UPDATE_FREEFALL_PAGENUMBER';
export const UPDATE_FREEFALL_DATA = 'UPDATE_FREEFALL_DATA';
export const UPDATE_FREEFALL_POWER = 'UPDATE_FREEFALL_POWER';

//websocket
export const REMOVE_SELECTED_NODES_REQUEST = 'REMOVE_SELECTED_NODES_REQUEST';
export const REMOVE_SELECTED_NODES_SUCCESS = 'REMOVE_SELECTED_NODES_SUCCESS';
export const REMOVE_SELECTED_NODES_FAILURE = 'REMOVE_SELECTED_NODES_FAILURE';

export const SOCKET_CONNECTION_INIT = 'SOCKET_CONNECTION_INIT';
export const SOCKET_CONNECTION_SUCCESS = 'SOCKET_CONNECTION_SUCCESS';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_CONNECTION_CLOSED = 'SOCKET_CONNECTION_CLOSED';
export const CLOSE_SOCKET_CONNECTION = 'CLOSE_SOCKET_CONNECTION';
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE';
export const SEND_SOCKET_MESSAGE = 'SEND_SOCKET_MESSAGE';

export const UPDATE_WHITEBOARD = 'UPDATE_WHITEBOARD';

//blockChain: all
export const UPDATE_HASH_DATA = 'UPDATE_HASH_DATA';
export const UPDATE_BLOCKCHAIN_LOADING = 'UPDATE_BLOCKCHAIN_LOADING';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';

export const UPDATE_BLOCK_DATA = 'UPDATE_BLOCK_DATA';
export const UPDATE_BLOCK_NONCE = 'UPDATE_BLOCK_NONCE';
export const UPDATE_BLOCK_MINING = 'UPDATE_BLOCK_MINING';

export const UPDATE_BLOCKCHAIN_DATA = 'UPDATE_BLOCKCHAIN_DATA';
export const UPDATE_BLOCKCHAIN_NONCE = 'UPDATE_BLOCKCHAIN_NONCE';
export const UPDATE_BLOCKCHAIN_MINING = 'UPDATE_BLOCKCHAIN_MINING';

export const UPDATE_SIGNATURE_PRIVATEKEY = 'UPDATE_SIGNATURE_PRIVATEKEY';
export const UPDATE_SIGNATURE_PUBLICKEY = 'UPDATE_SIGNATURE_PUBLICKEY';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_SIGNATURE = 'UPDATE_MESSAGE_SIGNATURE';

export const UPDATE_NEW_MESSAGE = 'UPDATE_NEW_MESSAGE';
export const UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS';

//Protractor
export const UPDATE_PROTRACTOR = 'UPDATE_PROTRACTOR';
export const RESET_MAP = 'RESET_MAP';
export const ADD_DOT = 'ADD_DOT';
export const UPDATE_DOTT = 'UPDATE_DOTT';
export const UPDATE_LITTLE_DOTS = 'UPDATE_LITTLE_DOTS';
export const DRAW_LINE = 'DRAW_LINE';

export const UPDATE_FIRST_ATTEMP = 'UPDATE_FIRST_ATTEMP';

// AI: game1
export const UPDATE_ROAD_FIRST_GAME = 'UPDATE_ROAD_FIRST_GAME';
export const UPDATE_AI_FIRST_GAME_STATE = 'UPDATE_AI_FIRST_GAME_STATE';

// AI: game2
export const UPDATE_ROAD_SECOND_GAME = 'UPDATE_ROAD_SECOND_GAME';
export const UPDATE_K_VALUE_SECOND_GAME = 'UPDATE_K_VALUE_SECOND_GAME';
export const UPDATE_AI_SECOND_GAME_STATE = 'UPDATE_AI_SECOND_GAME_STATE';

//gameTheory
export const CREATE_AUCTION_REQUEST = 'CREATE_AUCTION_REQUEST';
export const CREATE_AUCTION_SUCCESS = 'CREATE_AUCTION_SUCCESS';
export const CREATE_AUCTION_FAILURE = 'CREATE_AUCTION_FAILURE';

export const JOIN_AUCTION_REQUEST = 'JOIN_AUCTION_REQUEST';
export const JOIN_AUCTION_SUCCESS = 'JOIN_AUCTION_SUCCESS';
export const JOIN_AUCTION_FAILURE = 'JOIN_AUCTION_FAILURE';

export const BID_REQUEST = 'BID_REQUEST';
export const BID_SUCCESS = 'BID_SUCCESS';
export const BID_FAILURE = 'BID_FAILURE';

export const AUCTION_RESULT_REQUEST = 'AUCTION_RESULT_REQUEST';
export const AUCTION_RESULT_SUCCESS = 'AUCTION_RESULT_SUCCESS';
export const AUCTION_RESULT_FAILURE = 'AUCTION_RESULT_FAILURE';

export const RESTART_AUCTION = 'RESTART_AUCTION';

//Blank Game
export const UPDATE_BLANK = 'UPDATE_BLANK';
export const UPDATE_BLANK_SITUATION = 'UPDATE_BLANK_SITUATION';

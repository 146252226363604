import React, { Component } from 'react';
import GridComponent from '../../../components/codingGrid/Grid.js';

class CodingGrid extends Component {
  render() {
    return <GridComponent />;
  }
}

export default CodingGrid;
